import {AppAction} from "../actions/appActions";

export interface ISabit {
	id: number,
	tur?: string,
	tip?: string,
	isim: string,
	deger?: number
}

interface AppState {
	sabitler: ISabit[] | null;
}

const initialState: AppState = {
	sabitler: null,
};

const authReducer = (state = initialState, action: AppAction): AppState => {
	switch (action.type) {
		case 'SABITLER_KAYDET':
			return {...state, sabitler: action.payload};
		default:
			return state;
	}
};

export default authReducer;