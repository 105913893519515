import React, {lazy} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import Login from "../views/login/Login";
import {AppDispatch, useSelector} from "../redux/store";
import PageWrapper from "../layouts/full/logbook/PageWrapper";
import Logout from "../views/logout/Logout";
import HesapDetaylari from "../views/logbook/hesapDetaylari/HesapDetaylari";
import UrunlerTable from "../views/logbook/urunler/UrunlerTable";
import {logout} from "../redux/actions/authActions";
import Hesap from "../views/logbook/hesap/Hesap";
import Yonetim from "../views/logbook/yonetim/Yonetim";

/* ****Pages***** */
const Routes = () => {
	const LandingPage = Loadable(lazy(() => import('../views/landingPage/LandingPage')));
	const accessToken: any = useSelector((state) => state.auth.accessToken)
	const exp = useSelector(state => state.auth.exp);
	const dispatch = AppDispatch();
	if (exp != null && exp <= new Date().valueOf() / 1000) {
		dispatch(logout());
	}

	const publicRoutes: any[] = [
		{
			path: '/Menu',
			element: <Outlet/>,
			children: [
				{
					path: '/Menu',
					element: <LandingPage/>,
				},
				{
					path: '/Menu/:kategori',
					element: <LandingPage/>,
				}
			],
		},
		{
			path: '*',
			element: <Navigate to="/Menu"/>,
		},
	]
	return !accessToken ? [...publicRoutes,
		{
			path: '/Logbook',
			element: <Outlet/>,
			children: [
				{
					path: '/Logbook',
					element: <Login/>,
				},
				{
					path: '*',
					element: <Navigate to="/Logbook"/>,
				}
			]
		},
		{
			path: '/PortRoyal',
			element: <LandingPage/>
		},
	] : [...publicRoutes,
		{
			path: '/Logbook',
			element: <PageWrapper/>,
			children: [
				{
					path: '/Logbook',
					element: <UrunlerTable/>,
				},
				{
					path: '/Logbook/dashboard',
					element: <HesapDetaylari/>,
				},
				{
					path: '/Logbook/management',
					element: <Yonetim/>,
				},
				{
					path: '/Logbook/accounting',
					element: <Hesap/>,
				},
				{
					path: '/Logbook/logout',
					element: <Logout/>,
				},
				{
					path: '*',
					element: <Navigate to="/Logbook"/>,
				}
			],
		},
		{
			path: '/PortRoyal',
			element: <PageWrapper/>,
			children: [
				{
					path: '/PortRoyal/',
					element: <HesapDetaylari/>,
				},
				{
					path: '/PortRoyal/:kartNo',
					element: <HesapDetaylari/>,
				},
			]
		},
	];
}

export default Routes;
