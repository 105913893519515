export const login = (payload: { permissions: string[], accessToken: string }) => {
	return {
		type: 'LOGIN' as const,
		payload: payload
	};
};

export const logout = () => {
	return {
		type: 'LOGOUT' as const
	};
};

export type AuthAction = ReturnType<typeof login | typeof logout>;