import {Grid, ListItemAvatar, Paper, TableContainer, TablePagination} from "@mui/material";
import React, {useEffect, useState} from "react";
import LogbookApi from "../../../api/staffApi/logbookApi";
import {useSelector} from "../../../redux/store";
import {ISabit} from "../../../redux/reducers/appReducer";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {KeyboardArrowDown, KeyboardArrowRight, Receipt, ReceiptLong} from "@mui/icons-material";
import floatParser from "../../../helpers/floatParser";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import formatNumber from "../../../helpers/formatNumber";


const HesapDetaylari = ({hesapId}: { hesapId?: number }) => {
	const sabitlerListesi = useSelector(state => state.app.sabitler);
	const [hesap, setHesap] = useState<any>([]);
	const [cariler, setCariler] = useState<any>([]);
	const [hesapOzeti, setHesapOzeti] = useState<any>({});
	const [hesapPage, setHesapPage] = useState(0);
	const [hesapRowsPerPage, setHesapRowsPerPage] = useState(10);
	const [cariPage, setCariPage] = useState(0);
	const [cariRowsPerPage, setCariRowsPerPage] = useState(10);
	const [isletmeHesabi, setIsletmeHesabi] = useState(false)

	function Row(props: { row: any }) {
		const {row} = props;
		const [open, setOpen] = React.useState(false);

		return (
			<React.Fragment>
				<TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
					<TableCell>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
						</IconButton>
					</TableCell>
					<TableCell align="center">
						{row.tarih}
					</TableCell>
					{!isletmeHesabi &&
              <TableCell align="center">{`${row.mesai?.gun ?? 0} Gün, ${row.mesai?.saat ?? 0} Saat`}</TableCell>}
					{!isletmeHesabi && <TableCell align="center">{`${formatNumber(row.mesai?.ucret ?? 0)}`}</TableCell>}
					<TableCell align="center">{formatNumber((isletmeHesabi ? 1 : -1) * floatParser(row.degisim))}</TableCell>
					<TableCell align="center">{formatNumber((isletmeHesabi ? 1 : -1) * row.sonBakiye)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{row.hesabaGirenler && row.hesabaGirenler.length > 0 && <Box sx={{margin: 1}}>
                  <Typography variant="h6" gutterBottom component="div">
                      Hesaba Transferler
                  </Typography>
                  <Table size="small" aria-label="purchases">
                      <TableHead>
                          <TableRow>
                              <TableCell align="center">Miktar</TableCell>
                              <TableCell align="center">Kaynak</TableCell>
                              <TableCell align="center">Açıklama</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
												{row.hesabaGirenler?.map((kayit: any) => (
													<TableRow key={""}>
														<TableCell align="center">{formatNumber(kayit.miktar)}</TableCell>
														<TableCell align="center">{kayit.kaynak}</TableCell>
														<TableCell align="center">{kayit.aciklama}</TableCell>
													</TableRow>
												))}
                      </TableBody>
                  </Table>
              </Box>}
							{row.hesaptanCikanlar && row.hesaptanCikanlar.length > 0 && <Box sx={{margin: 1}}>
                  <Typography variant="h6" gutterBottom component="div">
                      Hesaptan Çıkanlar
                  </Typography>
                  <Table size="small" aria-label="purchases">
                      <TableHead>
                          <TableRow>
                              <TableCell align="center">Miktar</TableCell>
                              <TableCell align="center">Kaynak</TableCell>
                              <TableCell align="center">Açıklama</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
												{row.hesaptanCikanlar?.map((kayit: any) => (
													<TableRow key={""}>
														<TableCell align="center">{formatNumber(kayit.miktar)}</TableCell>
														<TableCell align="center">{kayit.hedef}</TableCell>
														<TableCell align="center">{kayit.aciklama}</TableCell>
													</TableRow>
												))}
                      </TableBody>
                  </Table>
              </Box>}
							{row.odemeler && row.odemeler.length > 0 && <Box sx={{margin: 1}}>
                  <Typography variant="h6" gutterBottom component="div">
                      Yapılan Ödemeler
                  </Typography>
                  <Table size="small" aria-label="purchases">
                      <TableHead>
                          <TableRow>
                              <TableCell align="center">Miktar</TableCell>
                              <TableCell align="center">Tür</TableCell>
                              <TableCell align="center">Açıklama</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
												{row.odemeler?.map((kayit: any) => (
													<TableRow key={""}>
														<TableCell align="center">{formatNumber(kayit.miktar)}</TableCell>
														<TableCell align="center">{kayit.tur}</TableCell>
														<TableCell align="center">{kayit.aciklama}</TableCell>
													</TableRow>
												))}
                      </TableBody>
                  </Table>
              </Box>}
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}

	const handleHesapChangePage = (event: unknown, newPage: number) => {
		setHesapPage(newPage);
	};

	const handleHesapChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHesapRowsPerPage(+event.target.value);
		setHesapPage(0);
	};
	const handleCariChangePage = (event: unknown, newPage: number) => {
		setCariPage(newPage);
	};

	const handleCariChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCariRowsPerPage(+event.target.value);
		setCariPage(0);
	};
	useEffect(() => {
		if (sabitlerListesi != null) {
			const sabitler = sabitlerListesi.reduce<Record<number, ISabit>>((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {} as Record<number, ISabit>);
			const hesapTip = sabitler[hesapId ?? 0]?.tip
			if (hesapId) setIsletmeHesabi(hesapTip === "NAKIT" || hesapTip === "KREDI" || hesapTip === "CARI")

			const reqMethod = !hesapId ? LogbookApi.getBireyselHesap : LogbookApi.getHesapDetaylari;

			reqMethod(hesapId)
				.then((res: any) => {
					const hesapKayitlari = res.bakiye_degisimleri?.map((x: any) => {
						const tarih = x.tarih;
						return {
							id: x.id,
							tarih,
							degisim: x.bakiye,
							sonBakiye: x.son_bakiye,
							odemeler: res.yapilan_odemeler?.filter((x: any) => x.tarih === tarih).map((odeme: any) => {
								return {
									aciklama: odeme.aciklama,
									tur: sabitler[odeme.tur]?.isim,
									miktar: odeme.miktar
								}
							}),
							hesaptanCikanlar: res.hesaptan_cikanlar?.filter((x: any) => x.tarih === tarih).map((odeme: any) => {
								return {
									aciklama: odeme.aciklama,
									hedef: sabitler[odeme.hedef]?.isim,
									miktar: odeme.miktar
								}
							}),
							hesabaGirenler: res.hesaba_girenler?.filter((x: any) => x.tarih === tarih).map((odeme: any) => {
								return {
									aciklama: odeme.aciklama,
									kaynak: sabitler[odeme.kaynak]?.isim,
									miktar: odeme.miktar
								}
							}),
							mesai: res.mesailer?.find((x: any) => x.tarih === tarih)
						}
					})
					setHesap(hesapKayitlari)
					setHesapOzeti({
						bakiye: floatParser(res.bakiye) ?? 0,
						cariBakiye: floatParser(res.cari_bakiye) ?? 0,
						buAyMesailer: res.bu_ay_mesailer,
						gecenAyMesailer: res.gecen_ay_mesailer,
					});
					setCariler(res.cari_hesap_kayitlari?.map((kayit: any) => {
						const yeni = sabitler[kayit.kaynak].tur === "HESAP";
						return {
							tarih: kayit.tarih,
							tur: yeni ? "Yeni Kayıt" : "Ödeme",
							miktar: kayit.miktar,
							aciklama: kayit.aciklama,
							sonBakiye: kayit.son_bakiye,
						}
					}))
				})
				.catch(e => console.warn(e))
		}
	}, [sabitlerListesi, hesapId]);

	return <Grid container spacing={2}>
		<Grid item xs={12} md={6}>
			<Paper sx={{padding: "1rem", marginBottom: "1rem"}}>
				<Typography variant="h6" gutterBottom component="div" padding={"1rem"}>
					Hesap Özeti
				</Typography>
				<List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
					<ListItem>
						<ListItemAvatar>
							<Avatar>
								<ReceiptLong/>
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Güncel Bakiye"
						              secondary={formatNumber((isletmeHesabi ? 1 : -1) * hesapOzeti.bakiye)}/>
					</ListItem>
					{!isletmeHesabi && <>
              <ListItem>
                  <ListItemAvatar>
                      <Avatar>
                          <Receipt/>
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Cari Bakiye" secondary={formatNumber(hesapOzeti.cariBakiye)}/>
              </ListItem>
              <ListItem>
                  <ListItemAvatar>
                      <Avatar>
                          <WorkIcon/>
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Bu Ay Mesailer"
                                secondary={`${hesapOzeti.buAyMesailer?.gun} Gün,  ${hesapOzeti.buAyMesailer?.saat} Saat`}/>
              </ListItem>
              <ListItem>
                  <ListItemAvatar>
                      <Avatar>
                          <WorkIcon/>
                      </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Geçen Ay Mesailer"
                                secondary={`${hesapOzeti.gecenAyMesailer?.gun} Gün,  ${hesapOzeti.gecenAyMesailer?.saat} Saat`}/>
              </ListItem>
          </>}
				</List>
			</Paper>

			{!isletmeHesabi && cariler && <TableContainer component={Paper}>
          <Typography variant="h6" gutterBottom component="div" padding={"1rem"}>
              Cariler
          </Typography>
          <Table size="small">
              <TableHead>
                  <TableRow>
                      <TableCell align="center">Tarih</TableCell>
                      <TableCell align="center">Tip</TableCell>
                      <TableCell align="center">Miktar</TableCell>
                      <TableCell align="center">Son Bakiye</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
								{cariler
									.slice(cariPage * cariRowsPerPage, cariPage * cariRowsPerPage + cariRowsPerPage)
									.map((row: any) => (
										<TableRow key={""}>
											<TableCell align="center">{row.tarih}</TableCell>
											<TableCell align="center">{row.tur}</TableCell>
											<TableCell align="center">{formatNumber(row.miktar)}</TableCell>
											<TableCell align="center">{formatNumber(row.sonBakiye)}</TableCell>
										</TableRow>
									))}
              </TableBody>
          </Table>

          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={cariler?.length}
              rowsPerPage={cariRowsPerPage}
              page={cariPage}
              onPageChange={handleCariChangePage}
              onRowsPerPageChange={handleCariChangeRowsPerPage}
              showFirstButton
              showLastButton
          />
      </TableContainer>}
		</Grid>
		<Grid item xs={12} md={6}>
			<TableContainer component={Paper}>
				<Typography variant="h6" gutterBottom component="div" padding={"1rem"}>
					Günlük Kayıtlar
				</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell/>
							<TableCell align="center">Tarih</TableCell>
							{!isletmeHesabi && <TableCell align="center">Mesai</TableCell>}
							{!isletmeHesabi && <TableCell align="center">Mesai Ücreti</TableCell>}
							<TableCell align="center">Değişim</TableCell>
							<TableCell align="center">Son Bakiye</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{hesap
							.slice(hesapPage * hesapRowsPerPage, hesapPage * hesapRowsPerPage + hesapRowsPerPage)
							.map((row: any) => (
								<Row key={row.id} row={row}/>
							))}
					</TableBody>
				</Table>

				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={hesap?.length}
					rowsPerPage={hesapRowsPerPage}
					page={hesapPage}
					onPageChange={handleHesapChangePage}
					onRowsPerPageChange={handleHesapChangeRowsPerPage}
					showFirstButton
					showLastButton
				/>
			</TableContainer>
		</Grid>
	</Grid>
}

export default HesapDetaylari;