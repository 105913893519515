import Api from '../index';

const MenuApi = {

	getFullMenu() {
		return Api.getPromise('/menu/');
	},

	updateUrunMevcut(pk: number) {
		return Api.putPromise('/menu/urunmevcutguncelle', {pk});
	},

	getMenuKategorileri() {
		return Api.getPromise('/menu/kategoriler');
	},

	getKategoriSayfalari(id: number) {
		return Api.getPromise('/menu/kategorisayfalar/' + id);
	},

	getUrunler(id: number) {
		return Api.getPromise('/menu/sayfaurunler/' + id);
	},

	getKampanyalar() {
		return Api.getPromise('/menu/kampanyalar');
	},

	getEtkinlikler() {
		return Api.getPromise('/menu/etkinlikler');
	},
};

export default MenuApi;
