import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import Links from 'src/constants/Links';
import store from "../redux/store";
import {startLoading, stopLoading} from "../redux/reducers/loadingReducer";

const baseUrl = Links.BASE_URL_WITHOUT_TENANT;
let accessTokenId = -1;
const Api = {
	putAccessTokenInfo(accessToken: any) {
		accessTokenId !== -1 && axios.interceptors.request.eject(accessTokenId);
		accessTokenId = axios.interceptors.request.use(
			(config: any) => {
				config.headers['Authorization'] = !accessToken ? undefined : `JWT ${accessToken}`;
				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	},


	getPromise(endpoint: string, params?: object | undefined) {
		return new Promise((resolve, reject) => {
			axios
				.get(baseUrl + endpoint, {params})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},

	getPromiseWithParams(endpoint: string, params: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'GET',
				params: params,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},

	postPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},

	postPromiseWithoutData(endpoint: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},


	deletePromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'DELETE',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},

	putPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response) => resolve(response.data))
				.catch((error) => reject(error?.response?.data));
		});
	},
};

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	(store.dispatch)(startLoading());
	return config;
});

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		(store.dispatch)(stopLoading());
		return response;
	},
	(error) => {
		(store.dispatch)(stopLoading());
		return Promise.reject(error);
	},
);

export default Api;
