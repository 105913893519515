import React from "react";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MaliTablolar from "./MaliTablolar";
import TumHesaplar from "./TumHesaplar";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const {children, value, index} = props;

	return (value === index) ? <Box sx={{pX: {xs: 0, md: 3}, pY: {xs: 1, md: 3}}}>{children}</Box> : null
}


const Yonetim = () => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{width: '100%'}}>
			<Box sx={{borderBottom: 1, borderColor: 'divider'}}>
				<Tabs value={value} onChange={handleChange}>
					<Tab label="Mali Tablolar" id={`simple-tab-${0}`}/>
					<Tab label="Tüm Hesaplar" id={`simple-tab-${1}`}/>
					<Tab label="Ayarlar" id={`simple-tab-${2}`}/>
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0}>
				<MaliTablolar/>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<TumHesaplar/>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={2}>
				Item Three
			</CustomTabPanel>
		</Box>
	);
}

export default Yonetim;