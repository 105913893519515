import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Button,
	Card,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import floatParser from "../../../helpers/floatParser";
import {gunsonuKalemModel} from "./Hesap";
import {ISabit} from "../../../redux/reducers/appReducer";

const PersonelOdemeleri = (
	{
		personelOdemeleri,
		setPersonelOdemeleri,
		personelGiderTuru,
		kasaHesap,
		bankaHesap,
		ortakHesaplar,
		calisanHesaplar,
		sabitler
	}: {
		personelOdemeleri: gunsonuKalemModel[],
		setPersonelOdemeleri: any,
		personelGiderTuru: number,
		kasaHesap: ISabit,
		bankaHesap: ISabit,
		ortakHesaplar: ISabit[],
		calisanHesaplar: ISabit[],
		sabitler: ISabit[]
	}) => {
	return <Accordion
		sx={{backgroundColor: "#ccc"}}
	>
		<AccordionSummary>
			<Typography>
				Personel Ödemeleri
			</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Grid container spacing={2}>
				{
					personelOdemeleri.map((gider, giderIndex) =>
						<Grid item xs={12} key={"gider-" + giderIndex}>
							<Card>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs={3}>
											<Autocomplete
												size={"small"}
												disablePortal
												options={["Avans", "Maaş Ödemesi"]}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Ödeme Tipi"/>}
												value={gider.aciklama}
												onChange={(event, value) => {
													setPersonelOdemeleri(personelOdemeleri.map((gider, index) => {
														if (index === giderIndex) return {...gider, aciklama: value};
														return gider;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={3}>
											<Autocomplete
												size={"small"}
												disablePortal
												options={[kasaHesap, bankaHesap, ...ortakHesaplar]}
												getOptionLabel={option => option.isim}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Kaynak"/>}
												value={sabitler.find(sabit => sabit.id === gider.kaynak)}
												onChange={(_event, value) => {
													setPersonelOdemeleri(personelOdemeleri.map((gider, index) => {
														if (index === giderIndex) return {...gider, kaynak: value.id};
														return gider;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={2}>
											<Autocomplete
												size={"small"}
												disablePortal
												options={calisanHesaplar}
												getOptionLabel={option => option.isim}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Çalışan"/>}
												value={sabitler.find(sabit => sabit.id === gider.hedef)}
												onChange={(_event, value) => {
													setPersonelOdemeleri(personelOdemeleri.map((gider, index) => {
														if (index === giderIndex) return {...gider, hedef: value.id};
														return gider;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={2}>
											<TextField
												size={"small"}
												fullWidth
												type={"number"}
												label={"Tutar"}
												value={gider.miktar}
												onChange={(event) => {
													setPersonelOdemeleri(personelOdemeleri.map((gider, index) => {
														if (index === giderIndex) return {
															...gider,
															miktar: floatParser(event.target.value)
														};
														return gider;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={2}>
											<Button onClick={() => {
												setPersonelOdemeleri(personelOdemeleri.filter((_, index) => index !== giderIndex))
											}}>
												Sil
											</Button>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)
				}
				<Grid item xs={12} alignItems={"right"}>
					<Button
						onClick={() => {
							setPersonelOdemeleri([
								...personelOdemeleri,
								{
									aciklama: "",
									miktar: 0,
									tur: personelGiderTuru,
									kaynak: 0,
									hedef: 0,
								}])
						}}>
						Yeni Ödeme Ekle
					</Button>
				</Grid>
			</Grid>
		</AccordionDetails>
	</Accordion>
}

export default PersonelOdemeleri;