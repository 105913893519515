import {AuthAction} from "../actions/authActions";
import {jwtDecode} from "jwt-decode";

interface AuthState {
	accessToken: string | null;
	username: string | null;
	email: string | null;
	exp: number | null;
	permissions: string[];
}

const initialState: AuthState = {
	accessToken: null,
	username: null,
	email: null,
	exp: null,
	permissions: [],
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
	switch (action.type) {
		case 'LOGIN':
			const decoded = jwtDecode(action.payload.accessToken)
			return {...state, ...decoded, ...action.payload};
		case 'LOGOUT':
			return {...initialState};
		default:
			return state;
	}
};

export default authReducer;