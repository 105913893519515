import AppBar from "./AppBar";
import {Outlet} from "react-router-dom";
import {Paper} from "@mui/material";
import {AppDispatch, useSelector} from "../../../redux/store";
import {useEffect} from "react";
import logbookApi from "../../../api/staffApi/logbookApi";
import {sabitlerKaydet} from "../../../redux/actions/appActions";

const PageWrapper = () => {
	const sabitler = useSelector(state => state.app.sabitler)
	const dispatch = AppDispatch();

	useEffect(() => {
		if (!sabitler) {
			logbookApi.getSabitler().then((response: any) => {
				dispatch(sabitlerKaydet(response))
			}).catch(e => console.warn(e))
		}
	}, []);

	return <>
		<AppBar/>
		<Paper sx={{
			display: "flex",
			width: {xs: "100%", md: "calc(100% - 2rem)"},
			height: 'calc(100% - 7rem)',
			marginX: {xs: ".2rem", md: "1rem"},
			marginTop: "1rem",
			paddingY: {xs: ".2rem", md: "1rem"},
			paddingX: {xs: ".2rem", sm: "1rem", md: "2rem", lg: "4rem", xl: "8rem"},
			backgroundColor: "white",
			alignItems: "start",
			overflowY: "scroll",
		}}>
			<Outlet/>
		</Paper>
	</>
}

export default PageWrapper