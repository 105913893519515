import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {Check, Warning} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import MenuApi from "../../../api/menuApi/menuApi";


const UrunlerTable = () => {
	const [urunler, setUrunler] = useState<any[]>();
	const [seciliUrun, setSeciliUrun] = useState<any>();

	useEffect(() => {
		MenuApi.getFullMenu().then((response: any) => {
			setUrunler(response)
		})
	}, []);

	const handleUrunMevcutGuncelle = () => {
		MenuApi.updateUrunMevcut(seciliUrun?.pk).then(() => {
			const urunlerCopy = (urunler ?? []).map(kategori => {
				return {
					...kategori,
					sayfalar: kategori.sayfalar.map((sayfa: any) => {
						return {
							...sayfa,
							urunler: sayfa.urunler.map((urun: any) => {
									if (urun.pk === seciliUrun.pk) {
										const yeniUrun = {...urun, mevcut: !seciliUrun.mevcut}
										setSeciliUrun(yeniUrun)
										return {...yeniUrun}
									}
									return {...urun}
								}
							)
						}
					})
				}
			});
			setUrunler([...urunlerCopy])
		}).catch(e => console.warn(e))
	}

	return <Grid container spacing={2}>
		{seciliUrun && <Dialog open={true} onClose={() => setSeciliUrun(undefined)} maxWidth={"md"}>
        <DialogTitle>
            <Typography>
							{seciliUrun.isim}
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} margin={"1rem"}>
                    <TextField
                        fullWidth
                        label={"Mevcut"}
                        value={seciliUrun.mevcut ? "Evet" : "Hayır"}
                        disabled
                        InputProps={{
													endAdornment: <Button sx={{color: "white"}}
													                      onClick={handleUrunMevcutGuncelle}>Değiştir</Button>
												}}
                    />
                </Grid>
                <Grid item xs={12} margin={"1rem"}>
                    <TextField
                        fullWidth
                        label={"Hazırlanış"}
                        value={seciliUrun.tarif}
                        multiline
                        maxRows={10}
                        disabled
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button sx={{color: "white"}} onClick={() => setSeciliUrun(undefined)}>Kapat</Button>
        </DialogActions>
    </Dialog>}
		{
			urunler && urunler.map((kategori: any) => {
				return (
					<Grid item xs={12} key={kategori.isim}>
						<Accordion sx={{width: "100%"}}>
							<AccordionSummary>
								<Typography variant={"h5"}>
									{kategori.isim}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{kategori.sayfalar && kategori.sayfalar.map((sayfa: any) => {
									return (
										<Accordion sx={{width: "100%", backgroundColor: "white"}} key={sayfa.isim}>
											<AccordionSummary>
												<Typography variant={"h5"}>
													{sayfa.isim}
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<TableContainer component={Paper}>
													<Table size={"small"}>
														<TableHead>
															<TableRow>
																<TableCell align="center">Ürün Adı</TableCell>
																<TableCell align="right">{sayfa.fiyat1 ?? "Fiyat"}</TableCell>
																{sayfa.fiyat2 && <TableCell align="right">{sayfa.fiyat2}</TableCell>}
																<TableCell align="center">Mevcut</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{sayfa.urunler.map((urun: any) => (
																<TableRow
																	key={sayfa.isim + "-" + urun.pk}
																	sx={{'&:last-child td, &:last-child th': {border: 0}}}
																>
																	<TableCell component="th" scope="urun">
																		{urun.isim}
																	</TableCell>
																	<TableCell align="right">{urun.fiyat}</TableCell>
																	{sayfa.fiyat2 &&
                                      <TableCell align="right">{urun.fiyat_2 == 0 ? "" : urun.fiyat_2}</TableCell>}
																	<TableCell align="center">{urun.mevcut ? <Check/> : <Warning/>}</TableCell>
																	<TableCell align="center">
																		<Button
																			sx={{color: "white"}}
																			onClick={() => setSeciliUrun(urun)}>
																			<Typography>Detay</Typography>
																		</Button>
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</TableContainer>
											</AccordionDetails>

										</Accordion>
									)
								})}

							</AccordionDetails>

						</Accordion>
					</Grid>)
			})
		}


	</Grid>;
}
export default UrunlerTable;