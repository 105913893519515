import Api from '../index';

const LogbookApi = {
	login(credentials: any) {
		return Api.postPromise('/logbook/token/', credentials).then((resp: any) => {
			return resp;
		});
	},

	getSabitler() {
		return Api.getPromise('/logbook/sabitler/');
	},

	getGunsonu(tarih: string) {
		return Api.getPromise('/logbook/gunsonukalem/', {tarih});
	},

	setGunsonu(model: any) {
		return Api.postPromise('/logbook/gunsonukalem/', model);
	},

	getBireyselHesap() {
		return Api.getPromise('/logbook/hesap/');
	},

	getHesapDetaylari(hesapId?: number) {
		return Api.getPromise('/logbook/hesap/', {hesapId});
	},

	getHesapRaporlari() {
		return Api.getPromise('/logbook/raporlar/');
	},
};

export default LogbookApi;
