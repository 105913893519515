import {createSlice} from '@reduxjs/toolkit';

const loadingReducer = createSlice({
	name: 'loading',
	initialState: false as boolean,
	reducers: {
		startLoading: (state) => true,
		stopLoading: (state) => false,
	},
});

export const {startLoading, stopLoading} = loadingReducer.actions;
export default loadingReducer.reducer;