import {CssBaseline, ThemeProvider} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {ThemeSettings} from './theme/Theme';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Routes from './routes/Routes';
import {useSelector} from "./redux/store";
import Api from "./api";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';
import Spinner from "./components/spinner/Spinner";

function App() {
	const routing = useRoutes(Routes());
	const accessToken = useSelector(state => state.auth.accessToken);
	Api.putAccessTokenInfo(accessToken)
	const theme = ThemeSettings();

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
				<CssBaseline/>
				<Spinner/>
				<ScrollToTop>{routing}</ScrollToTop>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
