import {gunsonuKalemModel} from "./Hesap";
import {ISabit} from "../../../redux/reducers/appReducer";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Button,
	Card,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import floatParser from "../../../helpers/floatParser";

const Transferler = (
	{
		transferler,
		setTransferler,
		transferKod,
		kasaHesap,
		bankaHesap,
		ortakHesaplar,
		calisanHesaplar,
		sabitler
	}: {
		transferler: gunsonuKalemModel[],
		setTransferler: any,
		transferKod: number,
		giderTipleri: ISabit[],
		kasaHesap: ISabit,
		bankaHesap: ISabit,
		ortakHesaplar: ISabit[],
		calisanHesaplar: ISabit[],
		sabitler: ISabit[]
	}) => {
	return <Accordion
		sx={{backgroundColor: "#ccc"}}
	>
		<AccordionSummary>
			<Typography>
				Transferler
			</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Grid container spacing={2}>
				{
					transferler.map((transfer, transferIndex) =>
						<Grid item xs={12} key={"transfer-" + transferIndex}>
							<Card>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<Autocomplete
												size={"small"}
												disablePortal
												options={[kasaHesap, bankaHesap, ...ortakHesaplar]}
												getOptionLabel={option => option.isim}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Kaynak"/>}
												value={sabitler.find(sabit => sabit.id === transfer.kaynak)}
												onChange={(_event, value) => {
													setTransferler(transferler.map((transfer, index) => {
														if (index === transferIndex) return {...transfer, kaynak: value.id};
														return transfer;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<Autocomplete
												size={"small"}
												disablePortal
												options={[kasaHesap, bankaHesap, ...ortakHesaplar, ...calisanHesaplar]}
												getOptionLabel={option => option.isim}
												disableClearable
												renderInput={(params) => <TextField {...params} label="Hedef"/>}
												value={sabitler.find(sabit => sabit.id === transfer.hedef)}
												onChange={(_event, value) => {
													setTransferler(transferler.map((transfer, index) => {
														if (index === transferIndex) return {...transfer, hedef: value.id};
														return transfer;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={4}>
											<TextField
												size={"small"}
												fullWidth
												type={"number"}
												label={"Tutar"}
												value={transfer.miktar}
												onChange={(event) => {
													setTransferler(transferler.map((transfer, index) => {
														if (index === transferIndex) return {
															...transfer,
															miktar: floatParser(event.target.value)
														};
														return transfer;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={10}>
											<TextField
												size={"small"}
												fullWidth
												type={"text"}
												label={"Açıklama"}
												value={transfer.aciklama}
												onChange={(event) => {
													setTransferler(transferler.map((transfer, index) => {
														if (index === transferIndex) return {
															...transfer,
															aciklama: event.target.value
														};
														return transfer;
													}))
												}}
											/>
										</Grid>
										<Grid item xs={2}>
											<Button onClick={() => {
												setTransferler(transferler.filter((_, index) => index !== transferIndex))
											}}>
												Sil
											</Button>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					)
				}
				<Grid item xs={12} alignItems={"right"}>
					<Button
						onClick={() => {
							setTransferler([
								...transferler,
								{
									aciklama: "",
									miktar: 0,
									tur: transferKod,
									kaynak: 0,
									hedef: 0,
								}])
						}}>
						Yeni Transfer Ekle
					</Button>
				</Grid>
			</Grid>
		</AccordionDetails>
	</Accordion>
}

export default Transferler;