import {RootState} from './store'; // Redux store'unun tipi
import {useSelector} from 'react-redux';

const hasPermission = (requiredPermission: string): boolean => {
	// Redux state'inden permissions'ı al
	const permissions = useSelector((state: RootState) => state.auth.permissions);

	// İstenen iznin permissions listesinde olup olmadığını kontrol et
	return permissions.includes(requiredPermission);
};

export default hasPermission;
