import {FormControlLabel, Grid, Paper, Switch, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import LogbookApi from "../../../api/staffApi/logbookApi";
import {useSelector} from "../../../redux/store";
import {ISabit} from "../../../redux/reducers/appReducer";
import floatParser from "../../../helpers/floatParser";
import {BarChart, LineChart, PieChart} from "@mui/x-charts";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import Box from "@mui/material/Box";

interface IGunlukKayit {
	nakit_satis: number,
	kredi_satis: number,
	toplam_satis: number,
	ma: number
}

interface ShowSeriesState {
	toplamSatis: boolean;
	krediSatis: boolean;
	nakitSatis: boolean;
	ma: boolean;
}

const MaliTablolar = () => {
	const sabitlerListesi = useSelector(state => state.app.sabitler);
	const [gunlukVeri, setGunlukVeri] = useState<any>({});
	const [aylikVeri, setAylikVeri] = useState<any>({});
	const [seciliDonem, setSeciliDonem] = useState<any>();
	const [baslangicTarih, setBaslangicTarih] = useState<string>(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
	const [bitisTarih, setBitisTarih] = useState<string>(dayjs().format('YYYY-MM-DD'));
	const [showSeries, setShowSeries] = useState<ShowSeriesState>({
		toplamSatis: true,

		krediSatis: false,
		nakitSatis: false,
		ma: true,
	});

	const toggleSeries = (seriesName: keyof ShowSeriesState) => {
		setShowSeries((prev) => ({
			...prev,
			[seriesName]: !prev[seriesName],
		}));
	};

	const seciliTarihler = Object.keys(gunlukVeri).filter((tarih) => {
		const baslangic = (baslangicTarih !== "") ? dayjs(tarih) >= dayjs(baslangicTarih) : true;
		const bitis = (bitisTarih !== "") ? dayjs(tarih) <= dayjs(bitisTarih) : true;
		return baslangic && bitis;
	})

	let donemler = Object.keys(aylikVeri)
	donemler = donemler.slice(donemler.length > 12 ? donemler.length - 12 : 0)

	const sabitler = (sabitlerListesi ?? []).reduce<Record<number, ISabit>>((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {} as Record<number, ISabit>);

	useEffect(() => {
		if (sabitlerListesi != null) {
			LogbookApi.getHesapRaporlari()
				.then((res: any) => {
					let maList: number[] = []
					let ms = 0;
					const gunlukKayitlar: Record<string, IGunlukKayit> = {}
					const aylikKayitlar: Record<string, any> = {}
					res.kredi_satislar?.forEach((satis: any) => {
						gunlukKayitlar[satis.tarih] = {
							nakit_satis: 0,
							kredi_satis: floatParser(satis.miktar),
							toplam_satis: floatParser(satis.miktar),
							ma: 0,
						};
						const donem = satis.tarih?.substring(0, 7)
						aylikKayitlar[donem] = !aylikKayitlar[donem]
							? {
								toplamSatis: floatParser(satis.miktar),
								toplamGider: 0,
								gun: 1,
								giderTipleri: {}
							}
							: {
								...aylikKayitlar[donem],
								toplamSatis: aylikKayitlar[donem].toplamSatis + floatParser(satis.miktar),
								gun: aylikKayitlar[donem].gun + 1
							}
					})
					res.nakit_satislar?.forEach((satis: any) => {
						const donem = satis.tarih?.substring(0, 7)
						gunlukKayitlar[satis.tarih] = {
							...gunlukKayitlar[satis.tarih],
							nakit_satis: floatParser(satis.miktar),
							toplam_satis: gunlukKayitlar[satis.tarih].kredi_satis + floatParser(satis.miktar),
							ma: 0,
						}
						if (maList.length > 6) {
							ms -= maList[0]
							maList = maList.slice(1)
						}
						maList.push(gunlukKayitlar[satis.tarih].toplam_satis);
						ms += gunlukKayitlar[satis.tarih].toplam_satis
						gunlukKayitlar[satis.tarih].ma = floatParser((ms / (maList.length)).toFixed(2));
						aylikKayitlar[donem] = {
							...aylikKayitlar[donem],
							toplamSatis: aylikKayitlar[donem].toplamSatis + floatParser(satis.miktar)
						}
					})
					res.giderler?.forEach((gider: any) => {
						const donem = gider.tarih?.substring(0, 7)
						const tur = sabitler[gider.tur].isim
						const miktar = floatParser(gider.miktar)
						aylikKayitlar[donem] = {
							...aylikKayitlar[donem],
							toplamGider: aylikKayitlar[donem].toplamGider + miktar,
							giderTipleri: {
								...aylikKayitlar[donem].giderTipleri,
								[tur]: ((aylikKayitlar[donem].giderTipleri)[tur] ?? 0) + miktar
							}
						}
					})
					setGunlukVeri(gunlukKayitlar)
					setAylikVeri(aylikKayitlar)
				})
				.catch(e => console.warn(e))
		}
	}, [sabitlerListesi]);

	const series = [
		showSeries.toplamSatis
			? {
				data: seciliTarihler.map((x) => gunlukVeri[x]?.toplam_satis || 0),
				label: 'Toplam Satış',
				yAxisId: 'leftAxisId',
				color: "green",
				showMark: false,
			}
			: null,
		showSeries.krediSatis
			? {
				data: seciliTarihler.map((x) => gunlukVeri[x]?.kredi_satis || 0),
				label: 'Kredi Satış',
				yAxisId: 'leftAxisId',
				color: "purple",
				showMark: false,
			}
			: null,
		showSeries.nakitSatis
			? {
				data: seciliTarihler.map((x) => gunlukVeri[x]?.nakit_satis || 0),
				label: 'Nakit Satış',
				yAxisId: 'leftAxisId',
				color: "yellow",
				showMark: false,
			}
			: null,
		showSeries.ma
			? {
				data: seciliTarihler.map((x) => gunlukVeri[x]?.ma || 0),
				label: 'Hareketli Ortalama',
				yAxisId: 'leftAxisId',
				color: "red",
				showMark: false,
			}
			: null,
	].filter((s): s is Exclude<typeof s, null> => s !== null);

	const aylikGiderler = aylikVeri[seciliDonem ?? donemler[donemler.length - 1]]?.giderTipleri ?? {}
	const aylikGiderTipleri = Object.keys(aylikGiderler)
	const aylikGiderlerChartData = aylikGiderTipleri.map((giderTipi: string) => {
		return {
			value: aylikGiderler[giderTipi],
			label: giderTipi
		}
	})
	const pieChartValueFormatter = (item: {
		value: number
	}) => `${item.value}₺ - ${(item.value * 100 / aylikVeri[seciliDonem ?? donemler[donemler.length - 1]]?.toplamGider).toFixed(1)}%`;

	return <Grid container spacing={2}>

		<Grid item xs={12}>
			<Paper sx={{padding: "1rem"}}>
				<Typography variant={"h5"}>
					Gelirler Tablosu
				</Typography>
				<Grid container>
					<Grid item xs={6} md={2}>
						<DatePicker
							value={dayjs(baslangicTarih)}
							onChange={
								(newValue) => setBaslangicTarih(newValue?.format("YYYY-MM-DD") ?? "")
							}
							maxDate={dayjs(bitisTarih)}
							minDate={undefined}
						/>
					</Grid>
					<Grid item xs={6} md={2}>
						<DatePicker
							value={dayjs(bitisTarih)}
							onChange={
								(newValue) => setBitisTarih(newValue?.format("YYYY-MM-DD") ?? "")
							}
							maxDate={dayjs(new Date())}
							minDate={dayjs(baslangicTarih)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box display="inline-flex" justifyContent="space-around" marginBottom={2}>
							<FormControlLabel
								control={
									<Switch
										checked={showSeries.toplamSatis}
										onChange={() => toggleSeries('toplamSatis')}
										color="primary"
									/>
								}
								label="Toplam Satış"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={showSeries.krediSatis}
										onChange={() => toggleSeries('krediSatis')}
										color="primary"
									/>
								}
								label="Kredi Satış"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={showSeries.nakitSatis}
										onChange={() => toggleSeries('nakitSatis')}
										color="primary"
									/>
								}
								label="Nakit Satış"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={showSeries.ma}
										onChange={() => toggleSeries('ma')}
										color="primary"
									/>
								}
								label="Hareketli Ortalama"
							/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<LineChart
							height={300}
							series={series}
							xAxis={[{
								scaleType: 'point', data: seciliTarihler
							}]}
							yAxis={[{id: 'leftAxisId'}]}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
		<Grid item xs={12}>
			<Paper sx={{padding: "1rem"}}>
				<Typography variant={"h5"}>
					Aylık Gelir Giderler (Son 12 Ay)
				</Typography>
				<Grid container>
					<Grid item xs={12} md={7}>
						<BarChart
							onAxisClick={(event, d) => setSeciliDonem(d?.axisValue)}
							margin={{bottom: 30, left: 100, right: 30, top: 50}}
							dataset={donemler.map((donem: string) => {
								return {
									...aylikVeri[donem],
									donem: donem
								}
							})}
							xAxis={[{scaleType: 'band', dataKey: 'donem'}]}
							series={
								[
									{dataKey: 'toplamSatis', label: 'Toplam Satış'},
									{dataKey: 'toplamGider', label: 'Toplam Giderler'}
								]

							}
							height={300}
						/>
					</Grid>
					<Grid item xs={12} md={5} sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}>
						<Typography width={"100%"}>
							{seciliDonem ?? donemler[donemler.length - 1]} Gider Dağılımı
						</Typography>
						<Box width={"100%"}>
							<PieChart
								series={[
									{
										data: aylikGiderlerChartData,
										highlightScope: {fade: 'global', highlight: 'item'},
										faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
										valueFormatter: pieChartValueFormatter
									},
								]}
								slotProps={{
									legend: {
										direction: "column",
										position: {
											vertical: 'middle',
											horizontal: 'right',
										},
										itemMarkWidth: 10,
										itemMarkHeight: 4,
										markGap: 5,
										itemGap: 10,
									},
								}}
								height={200}
							/>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Grid>


	</Grid>
}

export default MaliTablolar;