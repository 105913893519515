import {Grid, Paper} from "@mui/material";
import React from "react";
import {useSelector} from "../../../redux/store";
import {ISabit} from "../../../redux/reducers/appReducer";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HesapDetaylari from "../hesapDetaylari/HesapDetaylari";

interface IGunlukKayit {
	nakit_satis: number,
	kredi_satis: number,
	toplam_satis: number,
	ma: number
}

const TumHesaplar = () => {
	const sabitlerListesi = useSelector(state => state.app.sabitler);

	const sabitler = (sabitlerListesi ?? []).reduce<Record<number, ISabit>>((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {} as Record<number, ISabit>);
	const tipSiralamasi = ["NAKIT", "KREDI", "CARI", "ORTAK", "CALISAN"];
	const tipSiraMap = new Map(tipSiralamasi.map((tip, index) => [tip, index]));

	const hesapIdler = (sabitlerListesi?.filter((sabit: ISabit) => sabit.tur === "HESAP") ?? [])
		.sort((a, b) => {
			const tipA = tipSiraMap.get(a.tip ?? "") ?? tipSiralamasi.length;
			const tipB = tipSiraMap.get(b.tip ?? "") ?? tipSiralamasi.length;

			return tipA - tipB;
		}).map((x: ISabit) => x.id)
	const hesaplar = (sabitlerListesi?.filter((sabit: ISabit) => sabit.tur === "HESAP") ?? [])
		.reduce<Record<number, ISabit>>((acc, item) => {
			acc[item.id] = item;
			return acc;
		}, {} as Record<number, ISabit>);

	const [hesapId, setHesapId] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setHesapId(newValue);
	};

	return (
		<Box sx={{width: "100%", bgcolor: 'background.paper'}}>
			<Tabs
				value={hesapId}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
			>
				{
					hesapIdler.map((hesap_id: number) => <Tab label={hesaplar[hesap_id].isim} value={hesap_id}/>)
				}
			</Tabs>
			<Grid container spacing={2}>

				<Grid item xs={12}>
					<Paper sx={{padding: "1rem"}}>
						<HesapDetaylari hesapId={hesapId}/>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}

export default TumHesaplar;