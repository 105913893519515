import {mesaiModel} from "./Hesap";
import {ISabit} from "../../../redux/reducers/appReducer";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Button,
	Card,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";

const Mesailer = (
	{
		mesailer,
		setMesailer,
		calisanlar
	}: {
		mesailer: mesaiModel[],
		setMesailer: any,
		calisanlar: ISabit[]
	}) => {
	return <Accordion
		sx={{backgroundColor: "#ccc"}}
	>
		<AccordionSummary>
			<Typography>
				Mesailer
			</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Grid container spacing={2}>
				{
					mesailer.map((mesai, mesaiIndex) => {
							const calisan: any = calisanlar.find(sabit => sabit.id === mesai.calisan)
							return <Grid item xs={12} key={"calisan-" + mesaiIndex}>
								<Card>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={4}>
												<Autocomplete
													size={"small"}
													disablePortal
													options={calisanlar}
													getOptionLabel={option => option.isim}
													disableClearable
													renderInput={(params) => <TextField {...params} label="Çalışan"/>}
													value={calisanlar.find(sabit => sabit.id === mesai.calisan)}
													onChange={(_event, value) => {
														setMesailer(mesailer.map((calisan, index) => {
															if (index === mesaiIndex) return {
																...calisan,
																calisan: value.id,
																...(value.tip !== "TAM" && {ekstra: false}),
																...(value.tip === "YONETICI" ? {gun: 0, saat: 0} : {gun: 1, saat: 0})
															};
															return calisan;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<Autocomplete
													size={"small"}
													disablePortal
													options={[false, true]}
													getOptionLabel={(option: boolean) => option ? "Evet" : "Hayır"}
													disableClearable
													disabled={calisan?.tip === "YONETICI"}
													renderInput={(params) => <TextField {...params} label="Ekstra Mesai mi"/>}
													value={mesai.ekstra}
													onChange={(_event, value) => {
														setMesailer(mesailer.map((calisan, index) => {
															if (index === mesaiIndex) return {
																...calisan,
																ekstra: value,
																...(value ? {gun: 0, saat: 0} : {gun: 1, saat: 0})
															};
															return calisan;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<Autocomplete
													size={"small"}
													disablePortal
													options={["0", "1"]}
													disabled={calisan?.tip === "YONETICI"}
													disableClearable
													renderInput={(params) => <TextField {...params} label="Gün"/>}
													value={"" + mesai.gun}
													onChange={(_event, value) => {
														setMesailer(mesailer.map((calisan, index) => {
															if (index === mesaiIndex) return {
																...calisan,
																gun: parseInt(value) ?? 0
															};
															return calisan;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<TextField
													size={"small"}
													fullWidth
													type={"number"}
													label={"Saat"}
													value={mesai.saat}
													disabled={!mesai.ekstra && calisan?.tip !== "YONETICI"}
													onChange={(event) => {
														setMesailer(mesailer.map((calisan, index) => {
															const val = parseInt(event.target.value);
															if (index === mesaiIndex) return {
																...calisan,
																saat: isNaN(val) ? 0 : val
															};
															return calisan;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<Button onClick={() => {
													setMesailer(mesailer.filter((_, index) => index !== mesaiIndex))
												}}>
													Sil
												</Button>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						}
					)
				}
				<Grid item xs={12} alignItems={"right"}>
					<Button
						onClick={() => {
							setMesailer([
								...mesailer,
								{
									calisan: 0,
									gun: 1,
									saat: 0,
									ekstra: false
								}])
						}}>
						Yeni Mesai Ekle
					</Button>
				</Grid>
			</Grid>
		</AccordionDetails>
	</Accordion>
}

export default Mesailer;