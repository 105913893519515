import {gunsonuKalemModel} from "./Hesap";
import {ISabit} from "../../../redux/reducers/appReducer";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Button,
	Card,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import floatParser from "../../../helpers/floatParser";

const Cariler = (
	{
		cariKod,
		cariHesapKod,
		cariler,
		setCariler,
		calisanlar,
	}: {
		cariKod: number,
		cariHesapKod: number,
		cariler: gunsonuKalemModel[],
		setCariler: any,
		calisanlar: ISabit[]
	}) => {
	return <Accordion
		sx={{backgroundColor: "#ccc"}}
	>
		<AccordionSummary>
			<Typography>
				Cari Hesaplar
			</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Grid container spacing={2}>
				{
					cariler.map((cari, cariIndex) => {
							const yeniIslem = cari.kaynak === cariHesapKod;
							const hesap = yeniIslem ? cari.hedef : cari.kaynak
							return <Grid item xs={12} key={"cari-" + cariIndex}>
								<Card>
									<CardContent>
										<Grid container spacing={2}>
											<Grid item xs={3}>
												<Autocomplete
													size={"small"}
													disablePortal
													options={["Yeni", "Ödeme"]}
													disableClearable
													renderInput={(params) => <TextField {...params} label="Kayıt Tipi"/>}
													value={cari.kaynak === cariHesapKod ? "Yeni" : "Ödeme"}
													onChange={(event, value) => {
														setCariler(cariler.map((cari, index) => {
															if (index === cariIndex) return {
																...cari,
																hedef: value === "Yeni" ? hesap : cariHesapKod,
																kaynak: value === "Yeni" ? cariHesapKod : hesap
															};
															return cari;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<Autocomplete
													size={"small"}
													disablePortal
													options={calisanlar}
													getOptionLabel={option => option.isim}
													disableClearable
													renderInput={(params) => <TextField {...params} label="Hesap Sahibi"/>}
													value={calisanlar.find(sabit => sabit.id === cari.hedef)}
													onChange={(event, value) => {
														setCariler(cariler.map((cari, index) => {
															if (index === cariIndex) return {
																...cari,
																hedef: yeniIslem ? value.id : cariHesapKod,
																kaynak: yeniIslem ? cariHesapKod : value.id
															};
															return cari;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													size={"small"}
													fullWidth
													type={"number"}
													label={"Tutar"}
													value={cari.miktar}
													onChange={(event) => {
														setCariler(cariler.map((cari, index) => {
															if (index === cariIndex) return {
																...cari,
																miktar: floatParser(event.target.value)
															};
															return cari;
														}))
													}}
												/>
											</Grid>
											<Grid item xs={2}>
												<Button onClick={() => {
													setCariler(cariler.filter((_, index) => index !== cariIndex))
												}}>
													Sil
												</Button>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						}
					)
				}
				<Grid item xs={12} alignItems={"right"}>
					<Button
						onClick={() => {
							setCariler([
								...cariler,
								{
									aciklama: "",
									miktar: 0,
									tur: cariKod,
									kaynak: cariHesapKod,
									hedef: 0
								}])
						}}>
						Yeni Kayıt Ekle
					</Button>
				</Grid>
			</Grid>
		</AccordionDetails>
	</Accordion>
}

export default Cariler;