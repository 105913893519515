import {TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector,} from 'react-redux';
import {persistStore} from 'redux-persist';
import thunk from 'redux-thunk';
import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";


export const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppState = ReturnType<typeof rootReducer>;
export const AppDispatch = () => useAppDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
export const persistor = persistStore(store);

export default store;